var $ = jQuery.noConflict();

// global variables
var solutionsMenuItemWidth = 0;

$(document)
    .on('ready', function () {
        $('#menu-footer-menu').append($('.digiCopy').html());

        // testimonials slider
        $('#mission-slider').slick({
            centerMode: true,
            slidesToShow: 3,
            dots: true,
            responsive: [
                {
                    breakpoint: 1155,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
        // product
        if($('.product .slider .elements').length > 0) {
            // product slider
            $('.product .slider .elements')
                .on('init', function(event, slick, direction) {
                    var current = 1;
                    var total = $('.product .slider .elements .item').length;
                    if(total < 2) {
                        $('.product .slider .controls').remove();
                    }
                    else {
                        $('.product .slider .controls .counter').html(current + " of "+ total).parent().css('display', 'table');
                    }
                })
                .slick({
                    slidesToShow: 1,
                    dots: false,
                    infinite: false,
                    prevArrow: $('.product .slider .controls .left'),
                    nextArrow: $('.product .slider .controls .right'),
                    adaptiveHeight: true
                })
                .on('afterChange', function(event,info) {
                    var current = info.currentSlide + 1;
                    var total = info.slideCount;
                    $('.product .slider .controls .counter').html(current + " of "+ total);
                    // fix gallery on project page
                    fixProductSlider();
                })
            ;
        }
        // custom scrolls
        $(".teams .member .text").mCustomScrollbar({
            theme: "rounded-dots-dark",
            scrollbarPosition: "outside",
            scrollButtons: {
                enable: true
            }
        });
        // solutions navigate
        if($('.solutions-nav').length > 0) {
            $('.solutions-nav ul li').each(function () {
                solutionsMenuItemWidth += $(this).outerWidth(true);
            });

            $('.solutions-nav').attr('data-top', $('.solutions-nav').offset().top);
            $('.solutions-list .item').each(function(){
                $(this).attr('data-top', $(this).offset().top - $(window).height() / 2);
            });
            responsiveSolutionsMenu();
        }
        // get a quote -> add id to checkbox
        $(".quote-form form .checkbox").each(function(){
            var ob = $(this);
            ob.find('input').attr('id', ob.find('label').attr('for'));
        });
        // brands slider
        $('.brands .brands-items').slick({
            centerMode: false,
            infinite: false,
            slidesToShow: 5,
            dots: false,
            responsive: [
                {
                    breakpoint: 1001,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 581,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 401,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        $('.solution-info .container ul li, .product .to-right .specific ul li').each(function () {
            if($(this).find('span').length > 0) {
                $(this).addClass('accordion')
            }
        });

        // fix gallery on project page
        fixProductSlider();

        // startup triggers
        $(window).trigger('scroll');
        $(window).trigger('resize');
    })
    //solutions navigation
    .on('click', '.solutions-nav ul li a', function (e) {
        e.stopPropagation();
        var item = $($(this).attr('href'));
        $('html, body').animate({
            scrollTop: item.offset().top - 80 - 48
        }, 300);
    })
    // compact menu
    .on('click', "header .mob .hide-menu", function (e) {
        e.preventDefault();
        $(this).parent().removeClass('visible');
    })
    .on('click', "header .show-menu", function (e) {
        e.preventDefault();
        $(this).siblings('.mob').addClass('visible');
    })
    .on('change', '.projects .bar .right form table select', function(e) {
        e.preventDefault();
        setCookie('orderby', $(this).val(), {
            expires: 3600
        });
        return window.location.reload();
    })
    // banner scroll button
    .on('click', '.hero-banner>a', function (e) {
        e.preventDefault();
        var hash = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $('.hero-banner').height() + 60
        }, 200);
    })
    // accordions
    .on('click', 'ul li.accordion', function() {
        if($(this).hasClass('showed')) {
            $(this).removeClass('showed').find('span');
        }
        else {
            $(this).addClass('showed').find('span');
        }
    })
;
// scroll event
$(window)
    .scroll(function () {
        // general header
        if($(document).scrollTop() > 0)
            $('header').addClass('fly');
        else
            $('header').removeClass('fly');
        // solutions navigation
        if($('.solutions-nav').length > 0) {
            if($(window).scrollTop() > $('.solutions-nav').attr('data-top') - 80) {
                $('.solutions-nav').addClass('fix');
            }
            else {
                $('.solutions-nav').removeClass('fix');
            }
            // solutions menu
            var solutions = [];
            $('.solutions-list .item').each(function(){
                if($(window).scrollTop() >= $(this).attr('data-top')) {
                    solutions.push($(this).attr('id'));
                }
            });
            $('.solutions-nav ul li').removeClass('active');
            $("[href='#"+ solutions.pop() +"']").parent().addClass('active');
        }
    })
    .on('resize', function(){
        responsiveSolutionsMenu();
        // fix gallery on project page
        fixProductSlider();
    })
;

// solutions menu
function responsiveSolutionsMenu(){
    // if fixed
    if($('.solutions-nav').length > 0) {
        if($(window).width() < solutionsMenuItemWidth && !$('.solutions-nav').hasClass('mini')) {
            $('.solutions-nav').addClass('mini');
        }
        if($(window).width() >= solutionsMenuItemWidth && $('.solutions-nav').hasClass('mini')) {
            $('.solutions-nav').removeClass('mini');
        }
    }
}

function setCookie(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

function fixProductSlider() {
    if($(window).width() > 1024) {
        $('.product .to-right').css({
            paddingTop: ($('.product .slider').height() + 80 - 62)+'px'
        });
    }
    else {
        $('.product .to-right').removeAttr('style');
    }
}